<template>
  <main>
    <b-tabs>
      <b-tab title="Informasi Barang">
        <b-overlay :show="loadingInfoBarang">
          <b-card-actions :no-actions="true">
            <validation-observer ref="form">
              <b-row class="mb-2">
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading">
                      Informasi Produk
                    </h2>
                  </b-alert>
                </b-col>
                <b-col md="12" sm="12">
                  <b-form-group label="Nama Barang" label-for="">
                    <validation-provider #default="{ errors }" name="nama" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.nama" placeholder="Ex: Sarung Tangan Medis" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="4">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="kategori" :rules="{ required: true }">
                      <label for="">Kategori</label>
                      <b-input-group>
                        <v-select v-model="form.id_kategori" :options="kategoriOptions" :reduce="(option) => option.value"
                          label="text" placeholder="Silakan Pilih" />
                        <b-input-group-append>
                          <b-button variant="outline-success" @click="showModalKategori = true"><feather-icon
                              icon="PlusIcon" /></b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-modal v-model="showModalKategori" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                    centered title="Form Tambah Kategori">
                    <b-form>
                      <b-form-group>
                        <label for="kategori">Kategori</label>
                        <b-form-input v-model="formkategori" id="kategori" placeholder="Nama kategori" />
                      </b-form-group>
                    </b-form>

                    <template #modal-footer>
                      <section class="d-flex justify-content-end align-items-center">
                        <b-button size="sm" class="mr-1" variant="success" @click="submitKategori">
                          Tambah
                        </b-button>
                        <b-button size="sm" variant="danger" @click="showModalKategori = false">
                          Keluar
                        </b-button>
                      </section>
                    </template>
                  </b-modal>
                </b-col>
                <b-col sm="12" md="4">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="satuan" :rules="{ required: false }">
                      <label for="">Satuan</label>
                      <b-input-group>
                        <v-select v-model="form.id_satuan" :options="satuanOptions" :reduce="(option) => option.value"
                          label="text">
                          <template #search="{ attributes, events }">
                            <input class="vs__search" :required="!form.id_satuan" v-bind="attributes" v-on="events" />
                          </template>
                        </v-select>
                        <b-input-group-append>
                          <b-button variant="outline-success" @click="showModalSatuan = true"><feather-icon
                              icon="PlusIcon" /></b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-modal v-model="showModalSatuan" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                    centered title="Form Tambah Satuan">
                    <b-form>
                      <b-form-group>
                        <label for="satuan">Satuan</label>
                        <b-form-input v-model="formsatuan" id="satuan" placeholder="Nama satuan" />
                      </b-form-group>
                    </b-form>

                    <template #modal-footer>
                      <section class="d-flex justify-content-end align-items-center">
                        <b-button size="sm" class="mr-1" variant="success" @click="submitSatuan">
                          Tambah
                        </b-button>
                        <b-button size="sm" variant="danger" @click="showModalSatuan = false">
                          Keluar
                        </b-button>
                      </section>
                    </template>
                  </b-modal>
                </b-col>
                <b-col md="4" sm="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="kelompok_id" :rules="{ required: false }">
                      <label for="">Kelompok</label>
                      <b-input-group>
                        <!-- <b-form-select
                        v-model="form.kelompok_id"
                        :options="[
                          { value: 1, text: '1' },
                          { value: 2, text: '2' },
                        ]"
                        :state="errors.length > 0 ? false : null"
                      /> -->
                        <b-form-select v-model="form.kelompok_id" :options="kelompokOptions" :reduce="(option) => option.value"
                          label="text" />
                        <b-input-group-append>
                          <b-button variant="outline-success" @click="showModalKelompok = true"><feather-icon
                              icon="PlusIcon" /></b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-modal v-model="showModalKelompok" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                    centered title="Form Tambah Kelompok">
                    <b-form>
                      <b-form-group>
                        <label for="kelompok">Kelompok</label>
                        <b-form-input v-model="formkelompok" id="kelompok" placeholder="Nama kelompok" />
                      </b-form-group>
                    </b-form>

                    <template #modal-footer>
                      <section class="d-flex justify-content-end align-items-center">
                        <b-button size="sm" class="mr-1" variant="success" @click="submitKelompok">
                          Tambah
                        </b-button>
                        <b-button size="sm" variant="danger" @click="showModalKelompok = false">
                          Keluar
                        </b-button>
                      </section>
                    </template>
                  </b-modal>
                </b-col>
                <b-col md="12" sm="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="jenis_penyakit" :rules="{ required: false }">
                      <label for="">Jenis Penyakit</label>
                      <b-input-group>
                        <v-select v-model="form.jenis_penyakit" multiple :options="jenisPenyakitOptions"
                          :reduce="(option) => option.value" label="text" />
                        <b-input-group-append>
                          <b-button variant="outline-success" @click="showModalJenisPenyakit = true"><feather-icon
                              icon="PlusIcon" /></b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-modal v-model="showModalJenisPenyakit" cancel-variant="secondary" ok-title="Tambah"
                    cancel-title="Batal" centered title="Form Tambah Jenis Penyakit">
                    <b-form>
                      <b-form-group>
                        <label for="jenispenyakit">Jenis Penyakit</label>
                        <b-form-input v-model="formjenispenyakit" id="jenispenyakit" placeholder="Nama jenispenyakit" />
                      </b-form-group>
                    </b-form>

                    <template #modal-footer>
                      <section class="d-flex justify-content-end align-items-center">
                        <b-button size="sm" class="mr-1" variant="success" @click="submitJenisPenyakit">
                          Tambah
                        </b-button>
                        <b-button size="sm" variant="danger" @click="showModalJenisPenyakit = false">
                          Keluar
                        </b-button>
                      </section>
                    </template>
                  </b-modal>
                </b-col>
                <b-col md="12" sm="12">
                  <b-form-group label="Klasifikasi Usia">
                    <validation-provider #default="{ errors }" name="klasifikasi_usia" :rules="{ required: false }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.klasifikasi_usia"
                        placeholder="Anak-anak" />
                      <!-- <b-form-textarea v-model="form.min_pesanan"></b-form-textarea> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" sm="12">
                  <b-form-group label="Deskripsi">
                    <validation-provider #default="{ errors }" name="deskripsi" :rules="{ required: false }">
                      <!-- <b-form-input :state="errors.length > 0 ? false : null" v-model="form.deskripsi" placeholder="Deskripsi" /> -->
                      <b-form-textarea v-model="form.deskripsi"></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col md="6" sm="12" v-if="!isAdminGudang">
                  <b-form-group label="Harga Dasar" label-for="">
                    <validation-provider #default="{ errors }" name="harga_dasar" :rules="{ required: false }">
                      <b-input-group prepend="Rp." class="input-group-merge">
                        <b-form-input :state="errors.length > 0 ? false : null" v-model="form.harga_dasar" @keyup="doFormatRupiah()" placeholder="Harga Dasar" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading">
                      Spesifikasi Produk
                    </h2>
                  </b-alert>
                </b-col>
                <b-col md="6" sm="6">
                  <b-form-group label="Obat termasuk generik?">
                    <validation-provider #default="{ errors }" name="generik" :rules="{ required: false }">
                      <b-form-radio-group v-model="form.generik" :options="[
                        { value: 1, text: 'Ya' },
                        { value: 2, text: 'Tidak' },
                      ]" :state="errors.length > 0 ? false : null" value-field="value"
      text-field="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6">
                  <b-form-group label="Obat termasuk narkotik?">
                    <validation-provider #default="{ errors }" name="narkotik" :rules="{ required: false }">
                      <b-form-radio-group v-model="form.narkotik" :options="[
                        { value: 1, text: 'Ya' },
                        { value: 2, text: 'Tidak' },
                      ]" :state="errors.length > 0 ? false : null" value-field="value"
      text-field="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="6" sm="6">
                  <b-form-group label="Jenis Obat">
                    <validation-provider #default="{ errors }" name="jenis_obat" :rules="{ required: false }">
                      <b-form-radio-group v-model="form.jenis_obat" :options="jenisObatOptions"
                        :state="errors.length > 0 ? false : null" value-field="value"
      text-field="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6">
                  <b-form-group label="Milik?">
                    <validation-provider #default="{ errors }" name="milik" :rules="{ required: false }">
                      <b-form-radio-group v-model="form.milik" :options="[
                        { value: 1, text: 'Sendiri' },
                        { value: 2, text: 'Kosinyasi' },
                      ]" :state="errors.length > 0 ? false : null" value-field="value"
      text-field="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="6" sm="6">
                  <b-form-group label="Kondisi?">
                    <validation-provider #default="{ errors }" name="kondisi" :rules="{ required: false }">
                      <b-form-radio-group v-model="form.kondisi" :options="[
                        { value: 1, text: 'Baru' },
                        { value: 2, text: 'Bekas' },
                      ]" :state="errors.length > 0 ? false : null" value-field="value"
      text-field="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mb-2"><b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading">
                      Varian Produk
                    </h2>
                  </b-alert>
                </b-col>
                <b-col></b-col>
                <b-col sm="12" md="12">
                  <div class="d-flex justify-content-center">
                    <div class="table-responsive">
                      <b-table-simple hover small caption-top>
                        <b-thead>
                          <b-tr>
                            <b-th>Kode</b-th>
                            <b-th>Varian</b-th>
                            <b-th>Harga Dasar (Rp)</b-th>
                            <b-th>No. Batch</b-th>
                            <b-th>Expired Date</b-th>
                            <b-th style="width: 3%"></b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item, i) in varianitems" :key="i">
                            <b-td>
                              <b-form-input placeholder="Ex: 23213" v-model="item.kode" required />
                            </b-td>
                            <b-td>
                              <b-form-input placeholder="Ex: Ukuran XL" v-model="item.varian" required />
                            </b-td>
                            <b-td>
                              <!-- <b-input-group prepend="Rp." class="input-group-merge"> -->
                                <b-form-input v-model="item.harga_dasar" @keyup="doFormatRupiah(i)" placeholder="Harga Dasar" required />
                              <!-- </b-input-group> -->
                            </b-td>
                            <b-td>
                              <b-form-input placeholder="No. Batch" v-model="item.no_batch" required />
                            </b-td>
                            <b-td>
                              <b-form-input type="date" placeholder="Expired Date" v-model="item.expire_date" required />
                            </b-td>
                            <b-td>
                              <section v-if="i == 0">
                                <b-button @click.prevent="addVar" variant="primary" size="sm">
                                  <feather-icon icon="PlusIcon" />
                                </b-button>
                              </section>
                              <section v-else-if="i > 0">
                                <b-button @click.prevent="removeVar(i, item)" variant="flat-danger" size="sm">
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </section>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple hover small caption-top responsive>
                    </div>
                  </div>
                </b-col>
                <b-col></b-col>
              </b-row>

              <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h4 class="alert-heading">
                      Pengiriman (tidak wajib)
                    </h4>
                    <!-- <div class="alert-body">
        Lorem ipsum dolor sit amet
        <b-link class="alert-link">
          consectetur
        </b-link>
        adipisicing elit. Ducimus, laborum!
      </div> -->
                  </b-alert>
                </b-col>

              </b-row>
              <b-row>
                <b-col md="3" sm="6">
                  <b-form-group label="Berat">
                    <validation-provider #default="{ errors }" name="berat" :rules="{ required: false }">
                      <b-input-group append="gram">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.berat"
                          placeholder="1000" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3" sm="6">
                  <b-form-group label="Panjang">
                    <validation-provider #default="{ errors }" name="panjang" :rules="{ required: false }">
                      <b-input-group append="cm">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.panjang"
                          placeholder="100" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- </b-row>
              <b-row> -->
                <b-col md="3" sm="6">
                  <b-form-group label="Lebar">
                    <validation-provider #default="{ errors }" name="lebar" :rules="{ required: false }">
                      <b-input-group append="cm">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.lebar"
                          placeholder="10" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3" sm="6">
                  <b-form-group label="Tinggi">
                    <validation-provider #default="{ errors }" name="tinggi" :rules="{ required: false }">
                      <b-input-group append="cm">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.tinggi"
                          placeholder="10" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" sm="6">
                  <b-form-group label="Minimal Pesanan">
                    <validation-provider #default="{ errors }" name="min_pesanan" :rules="{ required: false }">

                      <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.min_pesanan"
                        placeholder="Minimal Pesanan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6">
                  <b-form-group label="Asuransi Pengiriman">
                    <validation-provider #default="{ errors }" name="asuransi_pengiriman" :rules="{ required: false }">
                      <b-form-select v-model="form.asuransi_pengiriman" :options="[
                        { value: 1, text: 'Wajib' },
                        { value: 2, text: 'Opsi' },
                      ]" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" sm="12">
                  <b-form-group label="Layanan Ekspedisi">
                    <validation-provider #default="{ errors }" name="layanan_ekspedisi" :rules="{ required: false }">
                      <v-select v-model="form.layanan_ekspedisi" multiple :options="layananEkspedisiOptions"
                        :reduce="(option) => option.value" label="text">
                        <template #search="{ attributes, events }">
                          <input class="vs__search" :required="!form.layanan_ekspedisi" v-bind="attributes"
                            v-on="events" />
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-end">
                <b-button @click.prevent="submit" variant="primary">
                  {{ label }}
                </b-button>
              </div>
            </validation-observer>
          </b-card-actions>
        </b-overlay>
      </b-tab>
      <!-- <b-tab v-if="canManagePrice" title="Pengaturan Harga"> -->
      <b-tab title="Konversi Satuan" :active="$route.query.tab && $route.query.tab == 'konversi' ? true : false">
        <b-card>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="tambah" variant="primary">
                <!-- v-if="allowCreate()" -->
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah
              </b-button>
              <!-- v-if="allowCreate() || allowUpdate()" -->
              <b-modal v-model="showModal" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal" centered title="Form Tambah">
                <b-form>
                  <!-- <b-form-group>
              <label for="no">Nomor Urutan</label>
              <b-form-input v-model="form2.no" id="gudang" placeholder="Nomor" type="number" />
            </b-form-group> -->
                  <b-form-group>
                    <label for="satuan">Pilih Satuan yang akan di konversikan</label>
                    <b-form-select v-model="form2.id_satuan" :options="id_satuan" label="satuan" />
                  </b-form-group>
                  <b-form-group>
                    <label for="nilai">Isi</label>

                    <b-input-group prepend="" class="input-group-merge">
                      <b-form-input type="number" v-model="form2.nilai" placeholder="Isi" />
                    </b-input-group>
                  </b-form-group>
                  <!-- <b-form-group>
              <label for="teritory">ID Barang</label>
              <b-form-input v-model="form2.barang_utama" id="gudang" placeholder="Barang" type="number" />
            </b-form-group> -->
                </b-form>

                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" class="mr-1" variant="success" @click="saveKonversi"> Simpan </b-button>
                    <b-button size="sm" class="mr-1" variant="primary" @click="resetForm"> Reset </b-button>
                    <b-button size="sm" variant="danger" @click="showModal = false"> Keluar </b-button>
                  </section>
                </template>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table id="table2" striped small hover responsive :items="items" :fields="fields2">
                <template #cell(id_barang)="data">
                  {{ getBarangName(data.item) }}
                </template>
                <template #cell(nilai)="{ item }">
                  {{ formatRupiah(item.nilai) }}
                </template>

                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(actions)="row">
                  <b-button v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info"> <feather-icon icon="EditIcon" /> </b-button
                  ><b-button v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" class="" variant="outline-danger">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="Pengaturan Harga">
        <b-card>
          <b-row>
            <b-col md="5">
              <b-form-group label="Varian">
                <validation-provider #default="{ errors }" name="varian" :rules="{ required: true }">
                  <v-select v-model="form2.varian_id" @change="cek" :options="varianItem" :reduce="(option) => option.barang_id" label="text" />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Tipe Konsumen">
                <!-- {{ tipe_konsumen }} -->
                <validation-provider #default="{ errors }" name="tipe_konsumen" :rules="{ required: true }">
                  <v-select v-model="form2.tipe_konsumen_id" @change="cek" :options="tipe_konsumen" :reduce="(option) => option.id" label="nama" />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Cek?">
                <b-button @click.prevent="cek" variant="primary"> Cek </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            striped
            hover
            bordered
            :fields="[
              { key: 'no', label: 'No' },
              { key: 'satuan', label: 'Satuan' },
              { key: 'share_jasa', label: 'Share Jasa' },
              { key: 'share_penjual', label: 'Share Penjual' },
              { key: 'harga_jual', label: 'Harga Jual' },
            ]"
            :items="konversiSatuanItem"
            class="mb-2"
          >
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(share_jasa)="{ item, index }">
              <b-form-input
                v-model="item.share_jasa"
                type="number"
                @keyup="
                  doFormatHarga({ index, item });
                  hargaJual(index, item);
                "
                placeholder="Share Jasa"
              />
            </template>
            <template #cell(share_penjual)="{ item, index }">
              <b-form-input
                v-model="item.share_penjual"
                type="number"
                @keyup="
                  doFormatHarga({ index, item });
                  hargaJual(index, item);
                "
                placeholder="Share Penjual"
              />
            </template>
            <template #cell(harga_jual)="{ item, index }">
              <b-form-input type="number" v-model="item.harga_jual" readonly placeholder="Harga Jual" />
            </template>
          </b-table>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" @click.prevent="saveHarga">Simpan</b-button>
          </div>
        </b-card>
      </b-tab>

      <b-tab title="Foto Barang">
        <b-card>
          <div class="table-responsive">
            <b-table
              striped
              hover
              bordered
              :fields="[
                { key: 'varian', label: 'Varian Utama' },
                { key: 'photo', label: 'Foto' },
                { key: 'aksi', label: 'Aksi' },
              ]"
              :items="varianFotoUtama"
              class="mb-2"
            >
              <template #cell(no)="{ index }">
                {{ index + 1 }}
              </template>
              <template #cell(photo)="{ item, index }">
                <img v-if="item.path" :src="item.path" style="width: 70px" alt="" class="d-block mb-1" />
                <input type="file" ref="file_foto" @change="onSelectedPhotoUtama($event, index)" />
              </template>
              <template #cell(aksi)="{ item, index }">
                <b-button variant="primary" @click.prevent="savePhoto(item)">Simpan</b-button>
              </template>
            </b-table>
          </div>
        </b-card>
        <b-card v-if="varianItemOnly.length > 0">
          <div class="table-responsive">
            <b-form-group label="Varian">
              <validation-provider #default="{ errors }" name="varian" :rules="{ required: true }">
                <v-select v-model="foto_varian_id" :options="varianItemOnly" :reduce="(option) => option.value" label="text" />
              </validation-provider>
            </b-form-group>
            <b-form-group label="Foto">
              <input type="file" ref="file_foto" multiple @change="onSelectedPhotoPendukungs($event, index)" />
              <b-button variant="primary" @click.prevent="savePhotos()">Tambah</b-button>
            </b-form-group>
            <b-table
              v-if="fotoBarangPendukung.length > 0"
              striped
              hover
              bordered
              :fields="[
                { key: 'no', label: 'No' },
                { key: 'photo', label: 'Foto' },
                { key: 'aksi', label: 'Aksi' },
              ]"
              :items="fotoBarangPendukung"
              class="mb-2"
            >
              <template #cell(no)="{ index }">
                {{ index + 1 }}
              </template>
              <template #cell(photo)="{ item, index }">
                <img v-if="item.path" :src="item.path" style="width: 70px" alt="" class="d-block mb-1" />
                <input type="file" ref="file_foto" @change="onSelectedPhotoPendukung($event, index)" />
              </template>
              <template #cell(aksi)="{ item, index }">
                <b-button variant="primary" @click.prevent="savePhoto(item)">Simpan</b-button>
                <b-button variant="danger" @click.prevent="hapusFoto(item)">Hapus</b-button>
              </template>
            </b-table>
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
  </main>
</template>
<script>
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BInputGroup,
  BOverlay,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BPagination,
  BInputGroupPrepend,
  BInputGroupAppend,
  BForm,
  BFormCheckbox,
  BTabs,
  BTab,
  BCard,
  BTable,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormFile,
  BFormTextarea,
    BAlert,
    BFormRadio,
    BFormRadioGroup,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
} from "bootstrap-vue";
import { getSubject } from "../../../libs/acl/utils";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  components: {
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    BInputGroup,
    BOverlay,
    Cleave,
    BTabs,
    BTab,
    BTable,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardActions,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormFile,
    BFormTextarea,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BPagination,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
    BForm,
    BAlert,
    BFormRadio,
    BFormRadioGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data: () => ({
    foto_varian_id: null,
    photos: "kosong",
    loadingInfoBarang: false,
    label: "Simpan",
    konsumenTypes: [],
    selected: null,
    tipe_konsumen: [],
    options2: {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
    },
    barangItem: {},
    varianItemOnly: [],
    varianItem: [],
    fotoBarang: [],
    fotoBarangPendukung: [],
    konversiSatuanItem: [],
    varianFoto: [],
    varianFotoUtama: [],
    file: null,
    form: {
      nama: null,
      jenis: 1,
      jenis_obat: null,
      generik: null,
      narkotik: null,
      klasifikasi_usia: null,
      jenis_penyakit: [1, 2],
      id_kategori: null,
      id_satuan: null,
      milik: null,
      deskripsi: null,
      kelompok_id: null,
      kondisi: null,
      min_pesanan: null,
      berat: null,
      panjang: null,
      lebar: null,
      tinggi: null,
      asuransi_pengiriman: null,
      layanan_ekspedisi: null,
      item: [
        {
          barang_id: null,
          kode: null,
          varian: null,
          harga_dasar: 0,
          // no_batch: 0,
          // expire_date: 0,
        },
      ],
    },
    canManagePrice: false,
    generikOptions: [
      // jenis options
      {
        value: 1,
        text: "Generik",
      },
      {
        value: 2,
        text: "Non-Generik",
      },
    ],
    selectedSatuan: null,
    selectedKategori: null,
    kelompokOptions: null,
    kategoriOptions: [],
    jenisObatOptions: [
      {
        value: 1,
        text: "Dalam",
      },
      {
        value: 2,
        text: "Luar",
      },
    ],
    jenisPenyakitOptions: [],
    layananEkspedisiOptions: [],
    satuanOptions: [],
    activeAction: null,
    showModalEdit: false,
    showModal: false,
    id: null,
    form2: {
      nilai: 0,
      id_satuan: null,
      tipe_konsumen_id: 0,
      varian_id: 0,
    },
    id_barang: [],
    id_satuan: [],
    perPage: 5,
    pageOptions: [3, 5, 10],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    infoModal: {
      id: "info-modal",
      title: "",
      content: "",
    },
    fields2: [
      {
        key: "no",
        label: "No",
      },
      { key: "satuan.satuan", label: "dari satuan" },
      { key: "nilai", label: "Jumlah" },
      // { key: "id_barang", label: "Satuan Terkecil" },
      { key: "actions", label: "Aksi" },
    ],
    varianitems: [],
    items: [],
    status: [
      {
        1: "Current",
        2: "Professional",
        3: "Rejected",
        4: "Resigned",
        5: "Applied",
      },
      {
        1: "light-primary",
        2: "light-success",
        3: "light-danger",
        4: "light-warning",
        5: "light-info",
      },
    ],
  }),
  watch: {
    foto_varian_id(val) {
      this.getPhotoPendukung();
    },
    form2: {
      handler(val) {
        this.cek();
      },
      deep: true,
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    addVar() {
      this.form.item.push({
        barang_id: 0,
        variant: null,
        kode: null,
        harga_dasar: 0,
        no_batch: 0,
        expire_date: null,
      });
      const items = Object.assign([], this.form.item);
      this.form.item = items;
      this.varianitems = items;
      console.log("hehe", this.form.item);
    },
    removeVar(i, item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Varian ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log("item", item);
          if (item.barang_id != 0) {
            let payload = {
              id: item.barang_id,
              fungsi: 1,
            };
            this.$store
              .dispatch("barang/save", [payload])
              .then((res) => {
                this.displaySuccess({
                  message: "Varian berhasil dihapus",
                });
                this.form.item.splice(i, 1);
              })
              .catch((err) => {
                this.displayError(err);
              });
          } else {
            this.form.item.splice(i, 1);
          }
        }
      });
    },
    onSelectedPhotoUtama(e, i) {
      const fileObj = e.target.files || e.dataTransfer.files;
      const file = fileObj[0];
      const images_ext = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
      if (!images_ext.includes(file.type)) {
        this.displayError({
          message: "Harap pilih file foto!",
        });
        return false;
      }
      this.varianFotoUtama[i].photo = file;
    },
    onSelectedPhotoPendukung(e, i) {
      const fileObj = e.target.files || e.dataTransfer.files;
      const file = fileObj[0];
      const images_ext = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
      if (!images_ext.includes(file.type)) {
        this.displayError({
          message: "Harap pilih file foto!",
        });
        return false;
      }
      this.fotoBarangPendukung[i].photo = file;
    },
    onSelectedPhotoPendukungs(e) {
      const fileObj = e.target.files || e.dataTransfer.files;
      const file = fileObj;
      const images_ext = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
      if (!images_ext.includes(file[0].type)) {
        this.displayError({
          message: "Harap pilih file foto!",
        });
        return false;
      }
      this.photos = file;
      console.log("e.target.files", file);
    },
    doFormatHarga({ index, item }) {
      console.log(this.konversiSatuanItem[index]);
      this.konversiSatuanItem[index].share_jasa = this.formatRupiah(this.konversiSatuanItem[index].share_jasa);
      this.konversiSatuanItem[index].share_penjual = this.formatRupiah(this.konversiSatuanItem[index].share_penjual);
      const prices = Object.assign([], this.konversiSatuanItem);
      this.konversiSatuanItem = [];
      this.konversiSatuanItem = prices;
    },
    doFormatRupiah(i) {
      this.form.item[i].harga_dasar = this.formatRupiah(this.form.item[i].harga_dasar);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async saveHarga() {
      const thereEmptyHarga = this.konversiSatuanItem.some((item) => this.unFormatRupiah(item.harga_jual) < 1);
      if (thereEmptyHarga) {
        this.displayError({
          message: "Harga tidak boleh 0",
        });
        return false;
      }
      const payloads = [];
      this.konversiSatuanItem.map((item) => {
        let payload = {
          tipe_konsumen_id: this.form2.tipe_konsumen_id,
          barang_id: this.form2.varian_id,
          satuan_id: item.id_satuan,
          share_jasa: this.unFormatRupiah(item.share_jasa),
          share_penjual: this.unFormatRupiah(item.share_penjual),
        };
        if (item.id) {
          payload.id = item.id;
        }
        payloads.push(payload);
      });
      console.log("payloads", payloads);
      try {
        await this.$store.dispatch("hargajual/save", payloads);
        this.displaySuccess({
          message: "Harga berhasil disimpan",
        });
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async savePhoto(item) {
      if (item.id != null) {
        let payload = {
          id: item.id,
          fungsi: 1,
        };
        await this.$store.dispatch("barang/savePhoto", payload);
      }
      const fd = new FormData();
      fd.append("photo", item.photo);
      fd.append("tipe", item.tipe);
      fd.append("barang_id", item.barang_id);
      if (item.photo != null) {
        try {
          await this.$store.dispatch("barang/savePhoto", fd);
          this.getPhoto();
          this.displaySuccess({
            message: "Photo berhasil disimpan",
          });
        } catch (e) {
          this.displayError(e);
          return false;
        }
      } else {
        this.displayError({
          message: "Photo tidak boleh kosong!",
        });
      }
    },
    hapusFoto(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Foto ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = {
            id: item.id,
            fungsi: 1,
          };
          this.$store
            .dispatch("barang/savePhoto", payload)
            .then((res) => {
              this.displaySuccess({
                message: "Foto berhasil dihapus!",
              });
              this.getPhoto();
              this.getPhotoPendukung();
            })
            .catch((err) => {
              this.displayError({
                message: err,
              });
            });
        }
      });
    },
    savePhotos() {
      if (this.foto_varian_id == null) {
        this.displayError({
          message: "Harap pilih Varian!",
        });
      } else if (this.photos == "kosong") {
        this.displayError({
          message: "Photo tidak boleh kosong!",
        });
      } else {
        this.photos.forEach((res) => {
          const fd = new FormData();
          fd.append("photo", res);
          fd.append("tipe", 0);
          fd.append("barang_id", this.foto_varian_id);
          this.$store
            .dispatch("barang/savePhoto", fd)
            .then((res) => {
              this.displaySuccess({
                message: "Photo berhasil disimpan",
              });
              this.getPhoto();
              this.getPhotoPendukung();
            })
            .catch((err) => {
              this.displayError(e);
            });
        });
      }
    },
    submit() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          // validation for varian
          const thereEmptyVarian = this.form.item.filter((item) => item.varian == null || item.kode == null);

          if (thereEmptyVarian.length > 0) {
            this.displayError({
              message: "Harap isi varian dan kode yang kosong",
            });
            return false;
          }
          let payload = this.form;
          payload.barang_utama = this.form2.barang_utama;
          payload.item.map((item) => {
            item.harga_dasar = this.unFormatRupiah(item.harga_dasar);
          });

          console.log("payload", payload);
          try {
            this.loadingInfoBarang = true;
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("barang/save2", payload);
            console.log("ajuan", ajuan);
            this.label = "Simpan";
            const message = this.$route.params.id ? "Barang berhasil diperbaharui" : "Barang berhasli ditambahkan";
            this.displaySuccess({
              message,
            });
            setTimeout(() => this.$router.push(), 1000);

            const action = this.$route.params.id ? "update" : "create";
            this.loadingInfoBarang = false;
            this.checkPermission(action);
            this.getDataKonversi();
            // this.getDatabarang();
            this.getStatus();
            this.getBarangId();
            // setTimeout(() => this.$router.push("/barang"), 1000);
          } catch (e) {
            this.loadingInfoBarang = false;
            this.label = "Simpan";
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getDataKonversi() {
      this.$store
        .dispatch("konversi/getData", { id_barang: this.$route.params.id })
        .then(() => {
          this.items = this.$store.state.konversi.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    // getDatabarang() {
    //   this.$store
    //     .dispatch("barang/getDataV2", {})
    //     .then(() => {
    //       let ter = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
    //       ter.map((item) => {
    //         item.value = item.id;
    //         item.text = item.nama + " - " + item.satuan.satuan;
    //       });
    //       this.id_barang = ter;
    //     })
    //     .catch((e) => console.error(e));
    // },
    getBarangName(data) {
      let find = this.id_barang.find((item) => item.id == data.id_barang);
      return find ? find.satuan.satuan : "-";
    },
    async getSatuan() {
      let satuan = await this.$store.dispatch("satuan/getData");
      satuan = JSON.parse(JSON.stringify(satuan));
      let newSatuan = [];
      satuan.map((item) => {
        newSatuan.push({
          text: item.satuan,
          value: item.id,
        });
      });
      this.id_satuan = newSatuan;
      this.satuanOptions = newSatuan;
    },
    async getKategori() {
      let kategori = await this.$store.dispatch("kategori/getData");
      kategori = JSON.parse(JSON.stringify(kategori));
      let newKategori = [];
      kategori.map((item) => {
        newKategori.push({
          text: item.kategori,
          value: item.id,
        });
      });

      this.kategoriOptions = newKategori;
    },
    async getJenisPenyakit() {
      let jenisPenyakit = await this.$store.dispatch("jenispenyakit/getData");
      jenisPenyakit = JSON.parse(JSON.stringify(jenisPenyakit));
      let newjenisPenyakit = [];
      jenisPenyakit.map((item) => {
        newjenisPenyakit.push({
          text: item.jenis_penyakit,
          value: item.id,
        });
      });

      this.jenisPenyakitOptions = newjenisPenyakit;
    },
    async getKelompok() {
      let kelompok = await this.$store.dispatch("kelompok/getData");
      kelompok = JSON.parse(JSON.stringify(kelompok));
      let newkelompok = [];
      kelompok.map((item) => {
        newkelompok.push({
          text: item.kelompok,
          value: item.id,
        });
      });

      this.kelompokOptions = newkelompok;
    },
    async getLayananEkspedisi() {
      let layananEkspedisi = await this.$store.dispatch("layananekspedisi/getData");
      layananEkspedisi = JSON.parse(JSON.stringify(layananEkspedisi));
      let newlayananEkspedisi = [];
      layananEkspedisi.map((item) => {
        newlayananEkspedisi.push({
          text: item.layanan_ekspedisi,
          value: item.id,
        });
      });

      this.layananEkspedisiOptions = newlayananEkspedisi;
    },
    async getPhoto() {
      let params = {
        barang_id: this.$route.params.id,
      };
      let fotoBarang = await this.$store.dispatch("barang/getPhoto", params);
      fotoBarang = JSON.parse(JSON.stringify(fotoBarang));

      let fotoBarangUtama = fotoBarang.data;
      var fotoVarian = [];
      console.log("fotoBarangUtama[0]", fotoBarangUtama[0]);
      this.varianItem.map((res, i) => {
        if (i == 0) {
          // let getVarian = this.fotoBarang.find(res => res.)
          fotoVarian.push({
            id: fotoBarangUtama[0] != null ? fotoBarangUtama[0].id : null,
            tipe: i == 0 ? 1 : 0,
            barang_id: res.barang_id,
            varian: res.varian,
            photo: fotoBarangUtama[0] != null ? fotoBarangUtama[0].photo : null,
            path: fotoBarangUtama[0] != null ? fotoBarangUtama[0].path : null,
          });
        }
      });

      // var fotoVarianUtama = fotoVarian.filter((res) => res.barang_id == this.barangItem.id);
      this.varianFotoUtama = JSON.parse(JSON.stringify(fotoVarian));
      console.log("varianFotoUtama", this.varianFotoUtama);
      // this.varianFotoUtama = JSON.parse(JSON.stringify(fotoVarianUtama));
    },
    async getPhotoPendukung() {
      let params = {
        barang_id: this.foto_varian_id,
      };
      let fotoBarang = await this.$store.dispatch("barang/getPhoto", params);
      fotoBarang = JSON.parse(JSON.stringify(fotoBarang));

      let fotoBarangPendukung = fotoBarang.data;
      this.fotoBarangPendukung = fotoBarangPendukung;
      console.log("fotoBarangPendukung", fotoBarangPendukung);
    },
    checkPermission(action) {
      const subject = getSubject(this.$route);

      if (!this.$can(action, subject)) {
        this.$router.push("/not-authorized");
      }
    },
    async getStatus() {
      const currentMenu = await this.currentMenu(this.$route.meta.parent);

      // get status for check current user can manage harga
      const listStatus = await this.$store.dispatch("statusrole/getData", {
        menu_id: currentMenu.id,
        level_id: this.user.level.id,
      });
      const canManagePrice = listStatus.find((status) => status.data_status == 1);
      this.canManagePrice = canManagePrice && this.$route.params.id ? true : false;
    },
    async getTypeKonsumen() {
      this.tipe_konsumen = [];
      const tipeKonsumen = await this.$store.dispatch("tipekonsumen/getData");
      tipeKonsumen.map(async (tipe) => {
        tipe.harga = 0;
        tipe.harga_id = null;
        tipe.barang_id = this.$route.params.id;
        const harga = await this.$store.dispatch("harga-barang/getData", {
          where: {
            barang_id: this.$route.params.id,
            tipe_konsumen_id: tipe.id,
          },
        });

        if (harga && harga.length > 0) {
          tipe.harga_id = harga[0].id;
          tipe.harga = this.formatRupiah(harga[0].harga);
        }
      });

      this.tipe_konsumen = tipeKonsumen;
    },
    resetForm() {
      this.form2 = {
        barang_utama: this.$route.params.id,
        nilai: 0,
        id_satuan: 0,
      };
    },
    saveKonversi() {
      if (this.form2.nilai == null || this.form2.nilai == "") {
        this.displayError(e);
        return false;
      }
      if (this.id) {
        this.form2.id = this.id;
      }
      const payload = this.form2;
      this.$store
        .dispatch("konversi/save", payload)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.getDataKonversi();
          this.getBarangId();
          this.displaySuccess({
            message: "Konversi harga berhasil disimpan!",
          });
          this.resetForm();
          this.showModal = false;
          this.showModalEdit = false;
        })
        .catch((e) => {
          //   this.pesanGagalHapus();
          this.displayError(e);
          return false;
        });
      // this.pesanGagalSimpan()
    },
    tambah() {
      this.activeAction = "tambah";
      this.id = null;
      this.resetForm();
      this.showModal = true;
    },
    edit(item) {
      let { satuan, barang } = item;
      this.id = item.id;
      this.form2 = item;
      this.activeAction = "edit";
      this.form2.nilai = item.nilai;
      if (satuan) this.form2.id_satuan = satuan.id;
      if (barang) this.form2.barang_utama = barang.id;
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data konversi harga ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("konversi/save", item)
            .then(() => {
              this.getDataKonversi();
              this.getBarangId();
              this.displaySuccess({
                message: "Konversi harga berhasil dihapus!",
              });
            })
            .catch((e) => {
              //   this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },

    hargaJual(index, item) {
      let getSatuan = this.konversiSatuanItem[index];
      let harga_jual = parseInt(this.unFormatRupiah(getSatuan.share_jasa)) + parseInt(this.unFormatRupiah(getSatuan.share_penjual));
      this.konversiSatuanItem[index].harga_jual = this.formatRupiah(harga_jual);
    },

    async cek() {
      let params = {
        barang_id: this.form2.varian_id,
        tipe_konsumen_id: this.form2.tipe_konsumen_id,
      };
      let hargaJual = await this.$store.dispatch("hargajual/getData", params);
      hargaJual = JSON.parse(JSON.stringify(hargaJual));
      var newKonversiSatuan = [];
      if (hargaJual[0] == null) {
        newKonversiSatuan.push({
          id_barang: this.barangItem.id,
          id_satuan: this.barangItem.satuan.id,
          satuan: this.barangItem.satuan.satuan,
          share_jasa: 0,
          share_penjual: 0,
          harga_jual: 0,
        });
        this.barangItem.konversi.map((item) => {
          newKonversiSatuan.push({
            id_barang: item.id_barang,
            id_satuan: item.satuan.id,
            satuan: item.satuan.satuan,
            share_jasa: 0,
            share_penjual: 0,
            harga_jual: 0,
          });
        });
        console.log("newKonversiSatuan", newKonversiSatuan);
        this.konversiSatuanItem = newKonversiSatuan;
      } else {
        var findHarga = hargaJual.find((res) => res.barang_id == this.barangItem.id && res.satuan_id == this.barangItem.satuan.id);
        newKonversiSatuan.push({
          id_barang: this.barangItem.id,
          id_satuan: this.barangItem.satuan.id,
          satuan: this.barangItem.satuan.satuan,
          share_jasa: findHarga ? this.formatRupiah(findHarga.share_jasa) : 0,
          share_penjual: findHarga ? this.formatRupiah(findHarga.share_penjual) : 0,
          harga_jual: findHarga ? this.formatRupiah(parseInt(findHarga.share_jasa) + parseInt(findHarga.share_penjual)) : 0,
        });
        this.barangItem.konversi.map((item) => {
          var findHarga = hargaJual.find((res) => res.barang_id == item.id_barang && res.satuan_id == item.satuan.id);
          newKonversiSatuan.push({
            id: findHarga ? findHarga.id : null,
            id_barang: item.id_barang,
            id_satuan: item.satuan.id,
            satuan: item.satuan.satuan,
            share_jasa: findHarga ? this.formatRupiah(findHarga.share_jasa) : 0,
            share_penjual: findHarga ? this.formatRupiah(findHarga.share_penjual) : 0,
            harga_jual: findHarga ? this.formatRupiah(parseInt(findHarga.share_jasa) + parseInt(findHarga.share_penjual)) : 0,
          });
        });
        this.konversiSatuanItem = newKonversiSatuan;
      }
    },
    async getBarangId() {
      if (this.$route.params.id) {
        // let barang = this.$store.getters["barang/getBarang"](this.$route.params.id);
        // if (!barang) {
        let barang = await this.$store.dispatch("barang/getDataByIdV2", this.$route.params.id);
        console.log("barang", barang);
        // }
        this.barangItem = barang;
        var newVarian = [];
        barang.varian_item.map((item) => {
          newVarian.push({
            barang_id: item.barang_id,
            kode: item.kode,
            varian: item.varian,
            harga_dasar: item.harga_dasar,
            no_batch: item.no_batch,
            expire_date: item.expire_date,
            text: item.varian,
            value: item.barang_id,
          });
        });

        this.varianItem = newVarian;
        let varianItemOnly = newVarian.filter((res, i) => i != 0);
        this.varianItemOnly = varianItemOnly;

        console.log("varianItemOnly", varianItemOnly);

        this.form2 = {
          barang_utama: barang.id,
        };
        this.form = {
          nama: barang.nama,
          jenis: barang.jenis,
          jenis_obat: barang.jenis_obat,
          generik: barang.generik,
          narkotik: barang.narkotik,
          klasifikasi_usia: barang.klasifikasi_usia,
          id_kategori: barang.kategori ? barang.kategori.id : null,
          id_satuan: barang.satuan ? barang.satuan.id : null,
          milik: barang.milik,
          deskripsi: barang.deskripsi,
          kelompok_id: barang ? barang.kelompok_id : null,
          kondisi: barang.kondisi,
          min_pesanan: barang.min_pesanan,
          berat: barang.berat,
          panjang: barang.panjang,
          lebar: barang.lebar,
          tinggi: barang.tinggi,
          asuransi_pengiriman: barang.asuransi_pengiriman,
        };
        console.log("barang", barang);
        var variant = [];
        barang.varian_item.map((el) => {
          variant.push({
            barang_id: el.barang_id,
            kode: el.kode,
            varian: el.varian,
            harga_dasar: el.harga_dasar,
            no_batch: el.no_batch,
            expire_date: el.expire_date,
          });
        });
        this.form.item = variant;
        this.varianitems = variant;
        this.getPhoto();
        this.getKonversiSatuanItemUtama();
      }
    },

    async getKonversiSatuanItemUtama() {
      this.form2.varian_id = this.varianItem[0].barang_id;
      this.form2.tipe_konsumen_id = this.tipe_konsumen[0].id;
      let params = {
        barang_id: this.varianItem[0].barang_id,
        tipe_konsumen_id: this.tipe_konsumen[0].id,
      };
      let hargaJual = await this.$store.dispatch("hargajual/getData", params);
      hargaJual = JSON.parse(JSON.stringify(hargaJual));
      var newKonversiSatuan = [];
      console.log("this.barangItem");
      if (hargaJual[0] == null) {
        newKonversiSatuan.push({
          id_barang: this.barangItem.id,
          id_satuan: this.barangItem.satuan.id,
          satuan: this.barangItem.satuan.satuan,
          share_jasa: 0,
          share_penjual: 0,
          harga_jual: 0,
        });
        this.barangItem.konversi.map((item) => {
          newKonversiSatuan.push({
            id_barang: item.id_barang,
            id_satuan: item.satuan.id,
            satuan: item.satuan.satuan,
            share_jasa: 0,
            share_penjual: 0,
            harga_jual: 0,
          });
        });
        console.log("newKonversiSatuan", newKonversiSatuan);
        this.konversiSatuanItem = newKonversiSatuan;
      } else {
        var findHarga = hargaJual.find((res) => res.barang_id == this.barangItem.id && res.tipe_konsumen_id && this.form2.tipe_konsumen_id && res.satuan_id == this.barangItem.satuan.id);
        newKonversiSatuan.push({
          id_barang: this.barangItem.id,
          id_satuan: this.barangItem.satuan.id,
          satuan: this.barangItem.satuan.satuan,
          share_jasa: findHarga ? this.formatRupiah(findHarga.share_jasa) : 0,
          share_penjual: findHarga ? this.formatRupiah(findHarga.share_penjual) : 0,
          harga_jual: findHarga ? this.formatRupiah(parseInt(findHarga.share_jasa) + parseInt(findHarga.share_penjual)) : 0,
        });
        this.barangItem.konversi.map((item) => {
          var findHarga = hargaJual.find((res) => res.barang_id == item.id_barang && res.tipe_konsumen_id && this.form2.tipe_konsumen_id && res.satuan_id == item.id_satuan);
          console.log("findHarga", findHarga);
          newKonversiSatuan.push({
            id: findHarga ? findHarga.id : null,
            id_barang: item.id_barang,
            id_satuan: item.satuan.id,
            satuan: item.satuan.satuan,
            share_jasa: findHarga ? this.formatRupiah(findHarga.share_jasa) : 0,
            share_penjual: findHarga ? this.formatRupiah(findHarga.share_penjual) : 0,
            harga_jual: findHarga ? this.formatRupiah(parseInt(findHarga.share_jasa) + parseInt(findHarga.share_penjual)) : 0,
          });
        });
        this.konversiSatuanItem = newKonversiSatuan;
      }
    },
  },
  async mounted() {
    this.getDataKonversi();
    // this.getDatabarang();
    // this.getStatus();
  },
  async created() {
    const action = this.$route.params.id ? "update" : "create";

    this.checkPermission(action);

    await this.getTypeKonsumen();

    this.getKategori();

    this.getJenisPenyakit();

    this.getKelompok();

    this.getLayananEkspedisi();

    this.getSatuan();

    // this.getStatus();

    this.getBarangId();
  },
};
</script>
