var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Informasi Barang"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Informasi Produk ")])])], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Barang",
      "label-for": ""
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Ex: Sarung Tangan Medis"
          },
          model: {
            value: _vm.form.nama,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nama", $$v);
            },
            expression: "form.nama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "kategori",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Kategori")]), _c('b-input-group', [_c('v-select', {
          attrs: {
            "options": _vm.kategoriOptions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text",
            "placeholder": "Silakan Pilih"
          },
          model: {
            value: _vm.form.id_kategori,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "id_kategori", $$v);
            },
            expression: "form.id_kategori"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              _vm.showModalKategori = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah Kategori"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitKategori
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalKategori = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalKategori,
      callback: function callback($$v) {
        _vm.showModalKategori = $$v;
      },
      expression: "showModalKategori"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "kategori"
    }
  }, [_vm._v("Kategori")]), _c('b-form-input', {
    attrs: {
      "id": "kategori",
      "placeholder": "Nama kategori"
    },
    model: {
      value: _vm.formkategori,
      callback: function callback($$v) {
        _vm.formkategori = $$v;
      },
      expression: "formkategori"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "satuan",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Satuan")]), _c('b-input-group', [_c('v-select', {
          attrs: {
            "options": _vm.satuanOptions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref4) {
              var attributes = _ref4.attributes,
                events = _ref4.events;
              return [_c('input', _vm._g(_vm._b({
                staticClass: "vs__search",
                attrs: {
                  "required": !_vm.form.id_satuan
                }
              }, 'input', attributes, false), events))];
            }
          }], null, true),
          model: {
            value: _vm.form.id_satuan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "id_satuan", $$v);
            },
            expression: "form.id_satuan"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              _vm.showModalSatuan = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah Satuan"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitSatuan
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalSatuan = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalSatuan,
      callback: function callback($$v) {
        _vm.showModalSatuan = $$v;
      },
      expression: "showModalSatuan"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "satuan"
    }
  }, [_vm._v("Satuan")]), _c('b-form-input', {
    attrs: {
      "id": "satuan",
      "placeholder": "Nama satuan"
    },
    model: {
      value: _vm.formsatuan,
      callback: function callback($$v) {
        _vm.formsatuan = $$v;
      },
      expression: "formsatuan"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "kelompok_id",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Kelompok")]), _c('b-input-group', [_c('b-form-select', {
          attrs: {
            "options": _vm.kelompokOptions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.kelompok_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kelompok_id", $$v);
            },
            expression: "form.kelompok_id"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              _vm.showModalKelompok = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah Kelompok"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitKelompok
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalKelompok = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalKelompok,
      callback: function callback($$v) {
        _vm.showModalKelompok = $$v;
      },
      expression: "showModalKelompok"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "kelompok"
    }
  }, [_vm._v("Kelompok")]), _c('b-form-input', {
    attrs: {
      "id": "kelompok",
      "placeholder": "Nama kelompok"
    },
    model: {
      value: _vm.formkelompok,
      callback: function callback($$v) {
        _vm.formkelompok = $$v;
      },
      expression: "formkelompok"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', [_c('validation-provider', {
    attrs: {
      "name": "jenis_penyakit",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('label', {
          attrs: {
            "for": ""
          }
        }, [_vm._v("Jenis Penyakit")]), _c('b-input-group', [_c('v-select', {
          attrs: {
            "multiple": "",
            "options": _vm.jenisPenyakitOptions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.form.jenis_penyakit,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jenis_penyakit", $$v);
            },
            expression: "form.jenis_penyakit"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              _vm.showModalJenisPenyakit = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah Jenis Penyakit"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitJenisPenyakit
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModalJenisPenyakit = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalJenisPenyakit,
      callback: function callback($$v) {
        _vm.showModalJenisPenyakit = $$v;
      },
      expression: "showModalJenisPenyakit"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "jenispenyakit"
    }
  }, [_vm._v("Jenis Penyakit")]), _c('b-form-input', {
    attrs: {
      "id": "jenispenyakit",
      "placeholder": "Nama jenispenyakit"
    },
    model: {
      value: _vm.formjenispenyakit,
      callback: function callback($$v) {
        _vm.formjenispenyakit = $$v;
      },
      expression: "formjenispenyakit"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Klasifikasi Usia"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "klasifikasi_usia",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Anak-anak"
          },
          model: {
            value: _vm.form.klasifikasi_usia,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "klasifikasi_usia", $$v);
            },
            expression: "form.klasifikasi_usia"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Deskripsi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "deskripsi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-textarea', {
          model: {
            value: _vm.form.deskripsi,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "deskripsi", $$v);
            },
            expression: "form.deskripsi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Spesifikasi Produk ")])])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Obat termasuk generik?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "generik",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-radio-group', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 2,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null,
            "value-field": "value",
            "text-field": "text"
          },
          model: {
            value: _vm.form.generik,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "generik", $$v);
            },
            expression: "form.generik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Obat termasuk narkotik?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "narkotik",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-radio-group', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 2,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null,
            "value-field": "value",
            "text-field": "text"
          },
          model: {
            value: _vm.form.narkotik,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "narkotik", $$v);
            },
            expression: "form.narkotik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Obat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jenis_obat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-radio-group', {
          attrs: {
            "options": _vm.jenisObatOptions,
            "state": errors.length > 0 ? false : null,
            "value-field": "value",
            "text-field": "text"
          },
          model: {
            value: _vm.form.jenis_obat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jenis_obat", $$v);
            },
            expression: "form.jenis_obat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Milik?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "milik",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-radio-group', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Sendiri'
            }, {
              value: 2,
              text: 'Kosinyasi'
            }],
            "state": errors.length > 0 ? false : null,
            "value-field": "value",
            "text-field": "text"
          },
          model: {
            value: _vm.form.milik,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "milik", $$v);
            },
            expression: "form.milik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kondisi?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kondisi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-radio-group', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Baru'
            }, {
              value: 2,
              text: 'Bekas'
            }],
            "state": errors.length > 0 ? false : null,
            "value-field": "value",
            "text-field": "text"
          },
          model: {
            value: _vm.form.kondisi,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kondisi", $$v);
            },
            expression: "form.kondisi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Varian Produk ")])])], 1), _c('b-col'), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('b-table-simple', {
    attrs: {
      "hover": "",
      "small": "",
      "caption-top": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Kode")]), _c('b-th', [_vm._v("Varian")]), _c('b-th', [_vm._v("Harga Dasar (Rp)")]), _c('b-th', [_vm._v("No. Batch")]), _c('b-th', [_vm._v("Expired Date")]), _c('b-th', {
    staticStyle: {
      "width": "3%"
    }
  })], 1)], 1), _c('b-tbody', _vm._l(_vm.varianitems, function (item, i) {
    return _c('b-tr', {
      key: i
    }, [_c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Ex: 23213",
        "required": ""
      },
      model: {
        value: item.kode,
        callback: function callback($$v) {
          _vm.$set(item, "kode", $$v);
        },
        expression: "item.kode"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Ex: Ukuran XL",
        "required": ""
      },
      model: {
        value: item.varian,
        callback: function callback($$v) {
          _vm.$set(item, "varian", $$v);
        },
        expression: "item.varian"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Harga Dasar",
        "required": ""
      },
      on: {
        "keyup": function keyup($event) {
          return _vm.doFormatRupiah(i);
        }
      },
      model: {
        value: item.harga_dasar,
        callback: function callback($$v) {
          _vm.$set(item, "harga_dasar", $$v);
        },
        expression: "item.harga_dasar"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "No. Batch",
        "required": ""
      },
      model: {
        value: item.no_batch,
        callback: function callback($$v) {
          _vm.$set(item, "no_batch", $$v);
        },
        expression: "item.no_batch"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "type": "date",
        "placeholder": "Expired Date",
        "required": ""
      },
      model: {
        value: item.expire_date,
        callback: function callback($$v) {
          _vm.$set(item, "expire_date", $$v);
        },
        expression: "item.expire_date"
      }
    })], 1), _c('b-td', [i == 0 ? _c('section', [_c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.addVar($event);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon"
      }
    })], 1)], 1) : i > 0 ? _c('section', [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.removeVar(i, item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1) : _vm._e()])], 1);
  }), 1)], 1)], 1)])]), _c('b-col')], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Pengiriman (tidak wajib) ")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Berat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "berat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "gram"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "1000"
          },
          model: {
            value: _vm.form.berat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "berat", $$v);
            },
            expression: "form.berat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Panjang"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "panjang",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "100"
          },
          model: {
            value: _vm.form.panjang,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "panjang", $$v);
            },
            expression: "form.panjang"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lebar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lebar",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "10"
          },
          model: {
            value: _vm.form.lebar,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "lebar", $$v);
            },
            expression: "form.lebar"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tinggi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tinggi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "10"
          },
          model: {
            value: _vm.form.tinggi,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tinggi", $$v);
            },
            expression: "form.tinggi"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Minimal Pesanan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "min_pesanan",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Minimal Pesanan"
          },
          model: {
            value: _vm.form.min_pesanan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "min_pesanan", $$v);
            },
            expression: "form.min_pesanan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asuransi Pengiriman"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asuransi_pengiriman",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Wajib'
            }, {
              value: 2,
              text: 'Opsi'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.asuransi_pengiriman,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "asuransi_pengiriman", $$v);
            },
            expression: "form.asuransi_pengiriman"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Layanan Ekspedisi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "layanan_ekspedisi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('v-select', {
          attrs: {
            "multiple": "",
            "options": _vm.layananEkspedisiOptions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref21) {
              var attributes = _ref21.attributes,
                events = _ref21.events;
              return [_c('input', _vm._g(_vm._b({
                staticClass: "vs__search",
                attrs: {
                  "required": !_vm.form.layanan_ekspedisi
                }
              }, 'input', attributes, false), events))];
            }
          }], null, true),
          model: {
            value: _vm.form.layanan_ekspedisi,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "layanan_ekspedisi", $$v);
            },
            expression: "form.layanan_ekspedisi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1)], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Konversi Satuan",
      "active": _vm.$route.query.tab && _vm.$route.query.tab == 'konversi' ? true : false
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.tambah
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Tambah ")], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.saveKonversi
          }
        }, [_vm._v(" Simpan ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Reset ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.showModal = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "satuan"
    }
  }, [_vm._v("Pilih Satuan yang akan di konversikan")]), _c('b-form-select', {
    attrs: {
      "options": _vm.id_satuan,
      "label": "satuan"
    },
    model: {
      value: _vm.form2.id_satuan,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "id_satuan", $$v);
      },
      expression: "form2.id_satuan"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Isi")]), _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Isi"
    },
    model: {
      value: _vm.form2.nilai,
      callback: function callback($$v) {
        _vm.$set(_vm.form2, "nilai", $$v);
      },
      expression: "form2.nilai"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "id": "table2",
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.items,
      "fields": _vm.fields2
    },
    scopedSlots: _vm._u([{
      key: "cell(id_barang)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.getBarangName(data.item)) + " ")];
      }
    }, {
      key: "cell(nilai)",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.nilai)) + " ")];
      }
    }, {
      key: "cell(no)",
      fn: function fn(_ref23) {
        var index = _ref23.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Pengaturan Harga"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Varian"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "varian",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref24) {
        var errors = _ref24.errors;
        return [_c('v-select', {
          attrs: {
            "options": _vm.varianItem,
            "reduce": function reduce(option) {
              return option.barang_id;
            },
            "label": "text"
          },
          on: {
            "change": _vm.cek
          },
          model: {
            value: _vm.form2.varian_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form2, "varian_id", $$v);
            },
            expression: "form2.varian_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Konsumen"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tipe_konsumen",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref25) {
        var errors = _ref25.errors;
        return [_c('v-select', {
          attrs: {
            "options": _vm.tipe_konsumen,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "nama"
          },
          on: {
            "change": _vm.cek
          },
          model: {
            value: _vm.form2.tipe_konsumen_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form2, "tipe_konsumen_id", $$v);
            },
            expression: "form2.tipe_konsumen_id"
          }
        })];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cek?"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cek($event);
      }
    }
  }, [_vm._v(" Cek ")])], 1)], 1)], 1), _c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "hover": "",
      "bordered": "",
      "fields": [{
        key: 'no',
        label: 'No'
      }, {
        key: 'satuan',
        label: 'Satuan'
      }, {
        key: 'share_jasa',
        label: 'Share Jasa'
      }, {
        key: 'share_penjual',
        label: 'Share Penjual'
      }, {
        key: 'harga_jual',
        label: 'Harga Jual'
      }],
      "items": _vm.konversiSatuanItem
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref26) {
        var index = _ref26.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(share_jasa)",
      fn: function fn(_ref27) {
        var item = _ref27.item,
          index = _ref27.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Share Jasa"
          },
          on: {
            "keyup": function keyup($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            }
          },
          model: {
            value: item.share_jasa,
            callback: function callback($$v) {
              _vm.$set(item, "share_jasa", $$v);
            },
            expression: "item.share_jasa"
          }
        })];
      }
    }, {
      key: "cell(share_penjual)",
      fn: function fn(_ref28) {
        var item = _ref28.item,
          index = _ref28.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Share Penjual"
          },
          on: {
            "keyup": function keyup($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            }
          },
          model: {
            value: item.share_penjual,
            callback: function callback($$v) {
              _vm.$set(item, "share_penjual", $$v);
            },
            expression: "item.share_penjual"
          }
        })];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function fn(_ref29) {
        var item = _ref29.item,
          index = _ref29.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "readonly": "",
            "placeholder": "Harga Jual"
          },
          model: {
            value: item.harga_jual,
            callback: function callback($$v) {
              _vm.$set(item, "harga_jual", $$v);
            },
            expression: "item.harga_jual"
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.saveHarga($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Foto Barang"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "table-responsive"
  }, [_c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "hover": "",
      "bordered": "",
      "fields": [{
        key: 'varian',
        label: 'Varian Utama'
      }, {
        key: 'photo',
        label: 'Foto'
      }, {
        key: 'aksi',
        label: 'Aksi'
      }],
      "items": _vm.varianFotoUtama
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref30) {
        var index = _ref30.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(photo)",
      fn: function fn(_ref31) {
        var item = _ref31.item,
          index = _ref31.index;
        return [item.path ? _c('img', {
          staticClass: "d-block mb-1",
          staticStyle: {
            "width": "70px"
          },
          attrs: {
            "src": item.path,
            "alt": ""
          }
        }) : _vm._e(), _c('input', {
          ref: "file_foto",
          attrs: {
            "type": "file"
          },
          on: {
            "change": function change($event) {
              return _vm.onSelectedPhotoUtama($event, index);
            }
          }
        })];
      }
    }, {
      key: "cell(aksi)",
      fn: function fn(_ref32) {
        var item = _ref32.item,
          index = _ref32.index;
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.savePhoto(item);
            }
          }
        }, [_vm._v("Simpan")])];
      }
    }])
  })], 1)]), _vm.varianItemOnly.length > 0 ? _c('b-card', [_c('div', {
    staticClass: "table-responsive"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Varian"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "varian",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref33) {
        var errors = _ref33.errors;
        return [_c('v-select', {
          attrs: {
            "options": _vm.varianItemOnly,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text"
          },
          model: {
            value: _vm.foto_varian_id,
            callback: function callback($$v) {
              _vm.foto_varian_id = $$v;
            },
            expression: "foto_varian_id"
          }
        })];
      }
    }], null, false, 3064920995)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Foto"
    }
  }, [_c('input', {
    ref: "file_foto",
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.onSelectedPhotoPendukungs($event, _vm.index);
      }
    }
  }), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.savePhotos();
      }
    }
  }, [_vm._v("Tambah")])], 1), _vm.fotoBarangPendukung.length > 0 ? _c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "hover": "",
      "bordered": "",
      "fields": [{
        key: 'no',
        label: 'No'
      }, {
        key: 'photo',
        label: 'Foto'
      }, {
        key: 'aksi',
        label: 'Aksi'
      }],
      "items": _vm.fotoBarangPendukung
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function fn(_ref34) {
        var index = _ref34.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(photo)",
      fn: function fn(_ref35) {
        var item = _ref35.item,
          index = _ref35.index;
        return [item.path ? _c('img', {
          staticClass: "d-block mb-1",
          staticStyle: {
            "width": "70px"
          },
          attrs: {
            "src": item.path,
            "alt": ""
          }
        }) : _vm._e(), _c('input', {
          ref: "file_foto",
          attrs: {
            "type": "file"
          },
          on: {
            "change": function change($event) {
              return _vm.onSelectedPhotoPendukung($event, index);
            }
          }
        })];
      }
    }, {
      key: "cell(aksi)",
      fn: function fn(_ref36) {
        var item = _ref36.item,
          index = _ref36.index;
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.savePhoto(item);
            }
          }
        }, [_vm._v("Simpan")]), _c('b-button', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.hapusFoto(item);
            }
          }
        }, [_vm._v("Hapus")])];
      }
    }], null, false, 2135032280)
  }) : _vm._e()], 1)]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }